import React, { useState, useEffect, useRef } from "react";
import Chat, { Bubble, useMessages, Card, CardMedia, CardTitle, CardText, CardActions, Carousel, Button, Modal, Notice, Video, Image, List, ListItem, Icon, Input, Popup, Text, Flex, FlexItem, IconButton } from "@chatui/core";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import "@chatui/core/dist/index.css";
import "./chatui-theme.css";
import axios from 'axios';
import { RWebShare } from "react-web-share";
import useStickyState from "./utils/sticky_state";
import useStateUseEffect from "./utils/state_useeffect";
import timeout from "./utils/delay";


const avatars = {
  'bot': "https://media.istockphoto.com/id/1010001882/vector/%C3%B0%C3%B0%C2%B5%C3%B1%C3%B0%C3%B1%C3%B1.jpg?s=612x612&w=0&k=20&c=1jeAr9KSx3sG7SKxUPR_j8WPSZq_NIKL0P-MA4F1xRw=",
  'user': "https://w7.pngwing.com/pngs/340/946/png-transparent-avatar-user-computer-icons-software-developer-avatar-child-face-heroes.png"
}

const initialMessages = [
  {
    type: 'text',
    content: { text: 'Hi, I am Syndi. I can help you with a variety of tasks' },
    user: { avatar: avatars.bot },
  },
  {
    type: 'text',
    content: { text: 'Not sure? You can choose one from below' },
    user: { avatar: avatars.bot },
  },  
  {
    type: 'image',
    content: {
      picUrl: 'https://www.techsmith.com/blog/wp-content/uploads/2021/09/Make-a-meme-butterfly.png',
    },
    user: { avatar: avatars.bot },
  },
];


export default function App() {
  
  const { messages, appendMsg, setTyping } = useMessages(initialMessages);
  const [open, setOpen] = useState(false);
  const [prompt_type, setPrompt_type] = useState('main_menu');
  const [isactive, setIsactive] = useState(true);
  const [defaultQuickReplies, setDefaultQuickReplies] = useState([]);
  const [fullQuickReplies, setFullQuickReplies] = useState([]);
  const [canRecord, setCanRecord] = useState(false);
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [isListening, setIsListening] = useState(false);
  const microphoneRef = useRef(null);
  const [cardActions, setCardActions] = useState(false);
  const [points, setPoints] = useStickyState(0, "points");
  const [feedback1, setFeedback1] = useStateUseEffect("", "feedback1");
  const [feedback2, setFeedback2] = useStateUseEffect("", "feedback2");


  const defaults = {
    position: "left",
    user: { avatar: avatars.bot },
    hasTime: true
  }

  useEffect(() => {
    if (isactive){
    fetch(process.env.REACT_APP_BACKEND_URL + "main_menu")
      .then((res) => res.json())
      .then((data) => {
        setDefaultQuickReplies(data['returnObject']);
        setFullQuickReplies(data['returnFullObject'])
        setIsactive(false);
      });
    }
  }, [isactive]);


  function GetReplies(val, type) {  
    return axios({
        'method':'GET',
        'url': process.env.REACT_APP_BACKEND_URL + prompt_type,
        'headers': {},
        'params': {
            'q': val
        },
      })
      .then(response => {
          console.log(val, type)
          console.log(response.data)
          return response.data
      })
      .catch((error) => {
          console.log(error)
      })
  }

  function PostData(email, text) {  
    return axios({
        'method':'GET',
        'url': process.env.REACT_APP_BACKEND_URL + prompt_type,
        'headers': {},
        'params': {
            email: email,
            text: text,
        },
      })
      .then(() => {
          return
      })
      .catch((error) => {
          console.log(error);
      })
  }  
  
  
  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleConfirm() {
    PostData(feedback1, feedback2);
    setOpen(false);
  }

  async function handleSend(type, val) {
    if (type === "text" && val.trim()) {
      appendMsg({
        type: "text",
        content: { text: val },
        position: "right",
        user: { avatar: avatars.user },
      });

      console.log(val, prompt_type)

      setTyping(true);

      setTimeout(async () => {
        const resp = await GetReplies(val, prompt_type);

        if (resp.constructor === Array){
          resp.forEach(async element => {
            const mergedObj = { ...element, ...defaults };
            appendMsg(mergedObj);
            await timeout(3000);
          });
        }
        else{
          const mergedObj = { ...resp, ...defaults };
          appendMsg(mergedObj);
          await timeout(3000);
        };

      }, 1000);
    }

    if (type === 'quick_replies' && val.trim()){
      appendMsg({
        type: "text",
        content: { text: val },
        position: "right",
        user: { avatar: avatars.user },
        hasTime: true
      });

      const resp = fullQuickReplies[val]['response'];
      if (resp.constructor === Array){
        for (const element of resp) {
          setTyping(true);
          await timeout(1000);
          const mergedObj = { ...element, ...defaults };
          appendMsg(mergedObj);
        }
      }
      else {
        setTimeout(() => {
          const mergedObj = { ...resp, ...defaults };
          appendMsg(mergedObj);
      // appendMsg({
      //   type: "text",
      //   content: { text: "Noted. How can I help you?" },
      //   position: "left",
      //   user: { avatar: avatars.bot },
      //   hasTime: true
      // });
        }, 1000);
      }
    }
    
    if (type === 'card_send' && val.trim()){
      console.log(val, prompt_type)
      setTyping(true);

      setTimeout(async () => {
        const resp = await GetReplies(val, prompt_type);

        if (resp.constructor === Array){
          resp.forEach(element => {
            const mergedObj = { ...element, ...defaults };
            appendMsg(mergedObj);
          });
        }
        else{
          const mergedObj = { ...resp, ...defaults };
          appendMsg(mergedObj);
        };

      }, 1000);
    }
  }

  function handleLinkClick(url) {
    console.log('url', url);
  }

  function handleQuickReplyClick(item) {
    setPrompt_type(fullQuickReplies[item.name]['key']);
    handleSend('quick_replies', item.name);
  }

  function handleCardActionsClick(type, val) {
    console.log(type, val, cardActions);
    if (!cardActions && type==='intro_card'){
      console.log(val, cardActions);
      handleSend('text', val);
      setCardActions(false);
    };
    if (!cardActions && type==='quiz_card'){
      console.log(val, cardActions);
      if (val['valid'] === true){
        setTyping(true);

        setTimeout(async () => {
          appendMsg({
            type: "text",
            user: { avatar: avatars.bot },
            position: "left",
            content: {text: "Correct!"}
          });
        }, 1000);

        setPoints(points + 10);
      }
      else {
        setTyping(true);
        setTimeout(async () => {
          appendMsg({
            type: "text",
            user: { avatar: avatars.bot },
            position: "left",
            content: {text: "Incorrect"}
          });
        }, 1000);
      }
      handleSend('card_send', val['entity']);
      setCardActions(false);
    };
  }
  
  function renderMessageContent(msg) {
    const { type, content } = msg;

    switch (type) {
      case 'text':
        return <Bubble content={content.text} />;
      
      case 'card':
        return (
          <Card size="xl">
            <CardMedia image={content.card} />
            <CardTitle>{content.title}</CardTitle>
            <CardText>{content.text}</CardText>
          </Card>
        );

      case 'game_intro':
          return (
            <Card size="xl">
              {/* <CardMedia image={content.image} /> */}
              <CardTitle>{content.text}</CardTitle>
              {/* <CardText>{content.text}</CardText> */}
              <CardActions direction="column" 
              >
                {content.categories.map((category) => <Button className={category} onClick={() => handleCardActionsClick('intro_card', category)}><Text key={category}>{category}</Text></Button>)}
              </CardActions>
            </Card>
          );
        
      case 'game_quiz':
            return (
              <Carousel>
              {content.options.map((option) =>  
                                    <Card size="xl">
                                      <CardMedia image={content.image} />
                                      <CardText>{option['text']}</CardText>
                                      <CardActions direction="column">
                                        <Button className={option['text']} onClick={() => handleCardActionsClick('quiz_card', option)} color="primary" position="center">Select</Button>
                                      </CardActions>
                                    </Card>
            )}
            </Carousel>
            );
          
          
      case 'image':
        return (
          <Bubble type="image">
            <img src={content.picUrl} alt="" />
          </Bubble>
        );

      case 'picture':
        return <Image src={content.picUrl} alt="Image" fluid/>;


      case 'carousel':
        return (
          <Carousel autoplay>
            {
            content.map(
              (img) =>  <Card size="xl">
                          <CardMedia image={img.src} />
                          <CardText>{img.text}</CardText>
                        </Card>
              )
            }
          </Carousel>
        );

      case 'list':
        return (
          <List>
            <ListItem content="内容1" />
            <ListItem content="内容2" />
            <ListItem content="内容3" />
          </List>
        );
      
      case 'notice':
        return (
          <Notice
            content='Notice'
            url='https://necolas.github.io/react-native-web/docs/installation/'
            onLinkClick={handleLinkClick}
            onClose={handleClose}
           />
        );

      case 'video':
        return (
          <div>
            <h3>Video</h3>
            <Video src={content.url} width="300" height="200" alt="Demo Video" />
          </div>
        );

      case 'modal':
        return (
          <div>
            <Button onClick={handleOpen}>Click to open form</Button>
            <Modal
              active={open}
              title="Feedback"
              showClose={false}
              onClose={handleClose}
              actions={[
                {
                  label: 'Confirm',
                  color: 'primary',
                  onClick: handleConfirm,
                },
                {
                  label: 'Back',
                  onClick: handleClose,
                },
              ]}
            >
                <h3>Email address</h3>
                <Input value={feedback1} onChange={val => setFeedback1(val)} placeholder="name@company.com" />

                <h3>Feedback</h3>
                <Input rows={3} maxLength={150} value={feedback2} onChange={val => setFeedback2(val)} placeholder="Please provide your feedback..." />
            </Modal>
          </div>
        );

      case 'popup':
        return (
          <div>
          <Button onClick={handleOpen}>{content.open}</Button>
          <Popup
            active={open}
            title={content.title}
            onClose={handleClose}
          >
            <div style={{padding:'0px 15px'}}>
              <p style={{padding:'10px'}}>{content.text1}</p>
              <Image src={content.url} alt="Responsive image" fluid />
              <p style={{padding:'10px'}}>{content.text2}</p>
            </div>
          </Popup>
        </div>
        );

      default:
        return null;
    }
  }


  const recorder = {
      canRecord: true,
      onStart() {
        console.log('Started');
        setIsListening(true);
        microphoneRef.current.classList.add("listening");
        SpeechRecognition.startListening({
          continuous: true,
        });
      },
      onEnd() {
        console.log('End');
        setIsListening(false);
        microphoneRef.current.classList.remove("listening");
        SpeechRecognition.stopListening();
      },
      onCancel() {
        console.log('Cancel');
        setIsListening(false);
        microphoneRef.current.classList.remove("listening");
        SpeechRecognition.stopListening();
        resetTranscript();
      }
  }

  const toolbar = [
      {
        type: 'image',
        icon: 'image',
        title: 'Upload',
      },
      {
        type: 'speech',
        icon: 'mic',
        title: 'Voice'
      }
    ]

  const navbar = {
      logo: 'https://media.istockphoto.com/id/1010001882/vector/%C3%B0%C3%B0%C2%B5%C3%B1%C3%B0%C3%B1%C3%B1.jpg?s=612x612&w=0&k=20&c=1jeAr9KSx3sG7SKxUPR_j8WPSZq_NIKL0P-MA4F1xRw=',
      title: 'Syndi',
      leftContent: {
        icon: 'share'
      },
  }

  function shareApp(){
    return (
      <div>
        <h1>Share the word with your friends!</h1>
        <RWebShare
          data={{
            text: "Share the word with your friends!",
            url: process.env.REACT_APP_FRONTEND_URL,
            title: process.env.REACT_APP_TITLE,
          }}
          onClick={() => console.log("Shared successfully!")}
        >
          <button>Share on Web</button>
        </RWebShare>
      </div>
    );
  }


  function renderNavbar	(){
    return (
      <Flex>
        <h2><Image 
        src="https://media.istockphoto.com/id/1010001882/vector/%C3%B0%C3%B0%C2%B5%C3%B1%C3%B0%C3%B1%C3%B1.jpg?s=612x612&w=0&k=20&c=1jeAr9KSx3sG7SKxUPR_j8WPSZq_NIKL0P-MA4F1xRw=" 
        height="50"/>
        </h2>
        <FlexItem style={{ marginLeft: '4em' }}>
          <h2>Syndi</h2>
        </FlexItem>
        <FlexItem position="right" style={{ marginLeft: '10em' }}>
          <div>
            <h2>{points}</h2>
            {/* <h2><IconButton icon="share"/></h2>
            <Modal
              active={open}
              title="Share"
              showClose={false}
              onClose={handleClose}
              actions={[
                {
                  label: 'Confirm',
                  color: 'primary',
                  onClick: handleConfirm,
                },
                {
                  label: 'Back',
                  onClick: handleClose,
                },
              ]}
            >
              {shareApp}
            </Modal> */}
          </div>
        </FlexItem>
      </Flex>
      );
  }

  function onToolbarClick	() {
    console.log('onToolbarClick');
  }


  return (
    <Chat
      locale="en-US"
      navbar={navbar}
      renderNavbar={renderNavbar}
      messages={messages}
      renderMessageContent={renderMessageContent}
      quickReplies={defaultQuickReplies}
      onQuickReplyClick={handleQuickReplyClick}
      onSend={handleSend}
      placeholder='Type here...'
      // inputType= {canRecord ? 'voice' : 'text'}
      // recorder = {recorder}
      // toolbar = {toolbar}
      // onToolbarClick = {onToolbarClick}

    />
  );

}
